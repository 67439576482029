import { defineComponent as _defineComponent } from 'vue'
import { environment } from "@/common/environments/environmentLoader";
import { getCookie } from "@/common/lib/cookie";
import { onMounted } from "vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { useNavigation } from "@/common/composables/useNavigation";


export default /*@__PURE__*/_defineComponent({
  __name: 'RedirectPage',
  setup(__props, { expose: __expose }) {
  __expose();

const userStore = useUserStore();
const { userSettings } = storeToRefs(userStore);

async function getUserHomePage(): Promise<string | undefined> {
  await userStore.reloadUser();
  const customUrl = userSettings?.value?.default_homepage;
  if (!customUrl || customUrl === "/") {
    return undefined;
  }
  return `${location.origin}${customUrl}`;
}

onMounted(async () => {
  const navigation = useNavigation();
  // See if we redirect URL (from an interrupted session)
  const redirectUrl = getCookie("ct_redirect_url");
  if (redirectUrl) {
    navigation.goToUrl(redirectUrl);
  }
  // Load the user's default home page.
  const customUrl = await getUserHomePage();
  // Get the global default home page.
  const defaultUrl = `${location.origin}${environment.require("DEFAULT_LANDING_PAGE")}`;
  navigation.goToUrl(customUrl ?? defaultUrl);
});

const __returned__ = { userStore, userSettings, getUserHomePage }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content body" }
const _hoisted_2 = { class: "user-line" }
const _hoisted_3 = {
  class: "source-browser-container-container",
  ref: "parentDiv"
}
const _hoisted_4 = {
  key: 0,
  class: "modules-container subpage-container"
}
const _hoisted_5 = { class: "scrollable" }
const _hoisted_6 = {
  key: 1,
  class: "subpage-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["HomeTitleBar"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["project-container", {
        '-wide': $setup.sourceBrowserExpanded,
        '-narrow': !$setup.sourceBrowserExpanded,
        'pointer-events-none opacity-50': $setup.workspacesState === $setup.WorkspacesState.NoWorkspaces,
      }])
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode($setup["UserButton"], {
              modelValue: $setup.userSelected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.userSelected) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({
            'pointer-events-none opacity-50': $setup.workspacesState === $setup.WorkspacesState.NoCurrent,
          })
          }, [
            _createVNode($setup["Textbox"], {
              modelValue: $setup.projectSearchRef,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.projectSearchRef) = $event)),
              type: "search",
              class: "padding",
              ref: "searchProjectsBox"
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createElementVNode("div", {
              class: _normalizeClass(["categories", { '-sb-retracted': !$setup.sourceBrowserExpanded }])
            }, [
              _cache[7] || (_cache[7] = _createElementVNode("span", { class: "title-line" }, [
                _createElementVNode("div", { class: "title" }, "Projects")
              ], -1 /* HOISTED */)),
              _createVNode($setup["TextButton"], {
                "data-test": "new-project",
                label: "New Project",
                icon: "new-project",
                menu: true,
                onClick: $setup.newProject,
                class: "new-project-button"
              }),
              _createVNode($setup["MenuListItem"], {
                label: "My Projects",
                icon: "claritype-thin",
                selected: $setup.showFilter($setup.ModuleType.User),
                onSelected: _cache[2] || (_cache[2] = ($event: any) => ($setup.setModuleType($setup.ModuleType.User)))
              }, null, 8 /* PROPS */, ["selected"]),
              ($setup.environment.requireBoolean('READER_VIEW_PUBLISHING_ENABLED'))
                ? (_openBlock(), _createBlock($setup["MenuListItem"], {
                    key: 0,
                    label: "Reader Views",
                    icon: "md:bar_chart",
                    selected: $setup.showFilter($setup.ModuleType.Reader),
                    onSelected: _cache[3] || (_cache[3] = ($event: any) => ($setup.setModuleType($setup.ModuleType.Reader)))
                  }, null, 8 /* PROPS */, ["selected"]))
                : _createCommentVNode("v-if", true),
              ($setup.environment.requireBoolean('ENABLE_PUBLISHING'))
                ? (_openBlock(), _createBlock($setup["MenuListItem"], {
                    key: 1,
                    label: "Published Projects",
                    icon: "md:share",
                    selected: $setup.showFilter($setup.ModuleType.Published),
                    onSelected: _cache[4] || (_cache[4] = ($event: any) => ($setup.setModuleType($setup.ModuleType.Published)))
                  }, null, 8 /* PROPS */, ["selected"]))
                : _createCommentVNode("v-if", true),
              ($setup.environment.requireBoolean('LOCAL_EXPORTS'))
                ? (_openBlock(), _createBlock($setup["MenuListItem"], {
                    key: 2,
                    label: "Exported Projects",
                    icon: "md:computer",
                    selected: $setup.showFilter($setup.ModuleType.Local),
                    onSelected: _cache[5] || (_cache[5] = ($event: any) => ($setup.setModuleType($setup.ModuleType.Local)))
                  }, null, 8 /* PROPS */, ["selected"]))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */)
          ], 2 /* CLASS */)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["SourceBrowser"], {
            onSelectTables: $setup.sourceBrowserTablesSelected,
            "parent-height": $setup.divHeight,
            onExpanded: $setup.adjustColumnWidth,
            "allow-workspace-switching": true
          }, null, 8 /* PROPS */, ["parent-height"])
        ], 512 /* NEED_PATCH */)
      ], 2 /* CLASS */),
      ($setup.subPage === 'modules')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode($setup["Modules"], {
                modules: $setup.modules,
                filtered: $setup.projectSearchRef.length > 0,
                "allow-deletes": $setup.allowDeletes,
                onOpenModule: $setup.openModule,
                onDeleteModule: _cache[6] || (_cache[6] = ($event: any) => ($setup.deleteModule($event)))
              }, null, 8 /* PROPS */, ["modules", "filtered", "allow-deletes"])
            ])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.subPage === 'user-settings')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode($setup["UserSettings"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
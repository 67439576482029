import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex items-center justify-center" }
const _hoisted_2 = { class: "flex min-h-[100px] min-w-[450px] max-w-[450px] flex-col gap-5 rounded-[5px] border border-gray-200 bg-white px-20 py-10 text-gray-500 shadow-modal dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200" }
const _hoisted_3 = { class: "mb-5 flex flex-row items-center gap-10 text-xxl font-bold leading-6" }
const _hoisted_4 = { class: "-mr-[15px] pr-15 text-lg font-normal text-gray-500 dark:text-gray-200" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-row items-center justify-end gap-10 pt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex h-[48px] w-full justify-between bg-gray-800" }, [
      _createElementVNode("img", {
        src: "/images/lockup.svg",
        class: "mx-10 my-0 h-[25px] w-[115px] self-center"
      })
    ], -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["Icon"], {
            name: $props.icon,
            color: $props.iconColor
          }, null, 8 /* PROPS */, ["name", "color"]),
          _createTextVNode(" " + _toDisplayString($props.title), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("form", null, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        (_ctx.$slots.footer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "footer")
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
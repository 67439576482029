<template>
  <div
    class="m-5 w-[290px] rounded-[5px] border-[1px] p-5 hover:border-orange"
    :class="{
      'border-orange': module.selected,
      'border-transparent': !module.selected,
    }"
  >
    <div
      @click.exact="emit('openModule', module)"
      @click.shift.stop="toggleSelected"
      :data-test="`module-${module.name}`"
    >
      <div class="cursor-pointer rounded-[5px] border-[1px] border-solid border-gray-700">
        <div class="flex justify-center">
          <img
            v-if="module.thumbnail"
            class="thumbnail h-[200px] w-[248px] object-contain"
            :src="module.thumbnail"
          />
          <img
            v-else
            class="svg-to-dark-gray h-[200px] w-[248px] object-contain p-20"
            src="/images/icons/claritype.svg"
          />
        </div>
      </div>
    </div>
    <span class="flex flex-col flex-nowrap justify-between px-2">
      <div class="w-100 flex flex-row items-center justify-between">
        <div class="py-[16px] text-lg font-bold">{{ module.name }}</div>
        <Dropdown v-if="menuItems.length > 0">
          <template #popper>
            <Menu :items="menuItems" v-close-popper></Menu>
          </template>

          <Tooltip :delay="{ show: 1000, hide: 100 }">
            <template #popper>Manage {{ module.name }}</template>
            <IconButton name="md:more_vert" size="xl" :dark-hover="true" />
          </Tooltip>
        </Dropdown>
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex flex-row flex-wrap justify-between text-gray-500">
          <span v-if="updatedDate">{{ updatedDate }}</span>
        </div>
        <div class="flex flex-row flex-wrap justify-between text-gray-500">
          <span v-if="module.owner_name && showPublishedBy">
            Published by {{ module.owner_name }}
          </span>
        </div>
      </div>
    </span>
  </div>

  <Dialog
    v-if="renamingModule !== undefined"
    title="Rename project"
    success-label="Rename"
    @succeeded="finishRenameModule"
    @cancelled="renamingModule = undefined"
  >
    <Textbox label="Project Name" v-model="renamingModule" :autoselect="true" />
  </Dialog>

  <Dialog
    v-if="duplicateProject !== undefined"
    title="Duplicate project"
    success-label="Duplicate"
    @succeeded="finishDuplicatingProject"
    @cancelled="duplicateProject = undefined"
  >
    <Textbox label="New Project Name" v-model="duplicateProject" :autoselect="true" />
  </Dialog>

  <Dialog
    v-if="deletingModule"
    title="Delete project?"
    success-label="Delete"
    @succeeded="emit('deleteModule')"
    @cancelled="deletingModule = false"
  >
    Are you sure you want to delete project {{ module.name }}? This cannot be undone.
  </Dialog>

  <Dialog
    v-if="assigningTag"
    title="Tag project"
    icon="md:sell"
    success-label="Tag"
    @succeeded="() => setModuleTag()"
    @cancelled="assigningTag = false"
  >
    <Textbox label="Tag" v-model="tagName" />
  </Dialog>
</template>

<script lang="ts" setup>
import Dialog from "@/common/components/Dialog.vue";
import IconButton from "@/common/components/IconButtonV2.vue";
import Menu from "@/common/components/Menu.vue";
import Textbox from "@/common/components/Textbox.vue";
import { useNavigation } from "@/common/composables/useNavigation";
import { environment } from "@/common/environments/environmentLoader";
import { useWorkspaceStore } from "@/common/stores/workspaceStore";
import {
  moduleHasType,
  ModuleMetadata,
  ModuleType,
  useUserModuleStore,
} from "@/common/stores/userModuleStore";
import { Dropdown, Tooltip } from "floating-vue";
import { DateTime } from "luxon";
import { computed, onMounted, ref, toRefs } from "vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
const userStore = useUserStore();
const { globalAdmin } = storeToRefs(userStore);

const {
  duplicateModule,
  renameModule,
  publishModule,
  publishLocalModule,
  publishReaderView,
  unshareReaderView,
} = useUserModuleStore();
const props = defineProps<{ module: ModuleMetadata }>();
const { module } = toRefs(props);
const emit = defineEmits(["openModule", "deleteModule"]);

const renamingModule = ref<string | undefined>();
const duplicateProject = ref<string | undefined>();
const deletingModule = ref(false);
const assigningTag = ref(false);
const tagName = ref("");

const menuItems = computed(() => {
  const items = [];
  if (module.value.moduleType === ModuleType.User) {
    items.push({
      key: "rename",
      label: "Rename",
      icon: "md:text_select_start",
      action: () => (renamingModule.value = module.value.name),
    });
    if (module.value.databricks_host) {
      items.push({
        key: "duplicate",
        label: "Duplicate",
        icon: "md:file_copy",
        action: () => (duplicateProject.value = module.value.name),
      });
    }
  }
  if (module.value.moduleType === ModuleType.User && module.value.databricks_host) {
    if (environment.requireBoolean("ENABLE_PUBLISHING")) {
      items.push({
        key: "publish",
        label: "Publish module",
        icon: "md:publish",
        action: () => publishModule(module.value.id),
      });
    }
    if (environment.requireBoolean("READER_VIEW_PUBLISHING_ENABLED")) {
      const readerPublished = module.value.published_reader_view;
      items.push({
        key: "publish_reader",
        label: readerPublished ? "Unshare Reader view" : "Share Reader view",
        icon: "md:bar_chart",
        action: () => toggleReaderView(readerPublished),
      });
      items.push({
        key: "tag_project",
        label: "Tag project",
        icon: "md:sell",
        action: () => (assigningTag.value = true),
      });
    }
    if (environment.requireBoolean("LOCAL_EXPORTS")) {
      items.push({
        key: "export-local",
        label: "Export locally",
        icon: "download",
        action: () => publishLocalModule(module.value.id),
      });
    }
    if (environment.requireBoolean("VIEW_IN_EXPLORER")) {
      items.push({
        key: "view-in-explorer",
        label: "View in Reader",
        icon: "explore-outline",
        action: () => useNavigation().openUrl(getReaderUrl()),
      });
    }
  }
  if (isDeletable.value) {
    items.push({
      key: "delete",
      label: "Delete",
      icon: "md:delete",
      action: () => (deletingModule.value = true),
    });
  }

  if (isUnshareable) {
    items.push({
      key: "unshare_reader",
      label: "Unshare Reader view",
      icon: "md:bar_chart",
      action: () => unshareReaderView(module.value.id),
    });
  }
  return items;
});

const updatedDate = computed(() => formatDate(module.value.updated));

function formatDate(date: DateTime): string | null {
  return date.toRelative();
}

const isDeletable = computed(() => {
  if (module.value.moduleType === ModuleType.User) {
    return true;
  }
  if (moduleHasType(module.value, ModuleType.Published)) {
    if (module.value.owned) {
      return true;
    }
    if (globalAdmin.value) {
      return true;
    }
  }
  return false;
});

const showPublishedBy = moduleHasType(
  module.value,
  ModuleType.Published,
  ModuleType.Local,
  ModuleType.Reader
);

const isUnshareable = module.value.moduleType === ModuleType.Reader && globalAdmin.value;

async function finishRenameModule() {
  if (!renamingModule.value) {
    return;
  }
  await renameModule(module.value.id, module.value.name, renamingModule.value);
  renamingModule.value = undefined;
}

async function finishDuplicatingProject() {
  if (!duplicateProject.value) {
    return;
  }
  await duplicateModule(module.value.id, duplicateProject.value);
}

function toggleSelected() {
  module.value.selected = !module.value.selected;
}

async function toggleReaderView(readerPublished: boolean | null) {
  await publishReaderView(module.value.id, readerPublished ?? false);
  // TODO: add to return value
  module.value.published_reader_view = !readerPublished;
}

async function setModuleTag() {
  if (tagName.value.trim().length === 0) {
    return;
  }
  await useUserModuleStore().tagModule(tagName.value, module.value.id);
  module.value.tag = tagName.value;
  tagName.value = "";
  assigningTag.value = false;
}

function getReaderUrl() {
  const moduleId = module.value.tag ?? module.value.id;
  return `/reader/modules/${moduleId}/explore`;
}

onMounted(() => useWorkspaceStore().initializeWorkspaceState());
</script>

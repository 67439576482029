<!-- A full-screen container for login-flow screens (login, signup, etc.) where we don't have a current user -->
<template>
  <div class="flex h-[48px] w-full justify-between bg-gray-800">
    <img src="/images/lockup.svg" class="mx-10 my-0 h-[25px] w-[115px] self-center" />
  </div>
  <div class="absolute inset-0 flex items-center justify-center">
    <div
      class="flex min-h-[100px] min-w-[450px] max-w-[450px] flex-col gap-5 rounded-[5px] border border-gray-200 bg-white px-20 py-10 text-gray-500 shadow-modal dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
    >
      <div class="mb-5 flex flex-row items-center gap-10 text-xxl font-bold leading-6">
        <Icon :name="icon" :color="iconColor" />
        {{ title }}
      </div>
      <div class="-mr-[15px] pr-15 text-lg font-normal text-gray-500 dark:text-gray-200">
        <form>
          <slot></slot>
        </form>
      </div>
      <div v-if="$slots.footer" class="flex flex-row items-center justify-end gap-10 pt-10">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import { IconColorSpec } from "@/common/lib/icons";

withDefaults(
  defineProps<{
    title: string;
    icon?: string;
    iconColor?: IconColorSpec;
  }>(),
  {
    icon: "claritype",
    iconColor: "orange",
  }
);
</script>

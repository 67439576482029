<template>
  <div class="content">Redirecting…</div>
</template>
<style lang="scss" scoped>
.content {
  font-size: 18px;
  margin: $ludicrous-margin;
}
</style>
<script lang="ts" setup>
import { environment } from "@/common/environments/environmentLoader";
import { getCookie } from "@/common/lib/cookie";
import { onMounted } from "vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { useNavigation } from "@/common/composables/useNavigation";

const userStore = useUserStore();
const { userSettings } = storeToRefs(userStore);

async function getUserHomePage(): Promise<string | undefined> {
  await userStore.reloadUser();
  const customUrl = userSettings?.value?.default_homepage;
  if (!customUrl || customUrl === "/") {
    return undefined;
  }
  return `${location.origin}${customUrl}`;
}

onMounted(async () => {
  const navigation = useNavigation();
  // See if we redirect URL (from an interrupted session)
  const redirectUrl = getCookie("ct_redirect_url");
  if (redirectUrl) {
    navigation.goToUrl(redirectUrl);
  }
  // Load the user's default home page.
  const customUrl = await getUserHomePage();
  // Get the global default home page.
  const defaultUrl = `${location.origin}${environment.require("DEFAULT_LANDING_PAGE")}`;
  navigation.goToUrl(customUrl ?? defaultUrl);
});
</script>

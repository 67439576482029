import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-10" }
const _hoisted_2 = {
  key: 0,
  class: "text-lg text-gray-200"
}
const _hoisted_3 = {
  key: 1,
  class: "text-lg text-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock($setup["Start"], { title: "Welcome" }, {
    footer: _withCtx(() => [
      (!$setup.connecting)
        ? (_openBlock(), _createBlock($setup["TextButton"], {
            key: 0,
            label: "Log in",
            disabled: !$setup.formValidated,
            onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.tryLogin()))
          }, null, 8 /* PROPS */, ["disabled"]))
        : (_openBlock(), _createBlock($setup["SpinnerButton"], {
            key: 1,
            label: "Logging in"
          }))
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode($setup["Textbox"], {
            label: "Email",
            modelValue: $setup.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
            autocomplete: "email",
            id: "email-field",
            disabled: $setup.connecting,
            "error-text": $setup.emailError ?? '',
            onFocus: _cache[1] || (_cache[1] = ($event: any) => ($setup.emailError = '')),
            onBlur: $setup.blurEmail
          }, null, 8 /* PROPS */, ["modelValue", "disabled", "error-text"]),
          _createVNode($setup["Textbox"], {
            label: "Password",
            modelValue: $setup.password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.password) = $event)),
            type: "password",
            autocomplete: "current-password",
            id: "password-field",
            disabled: $setup.connecting,
            onFocus: _cache[3] || (_cache[3] = ($event: any) => ($setup.passwordError = '')),
            onBlur: $setup.blurPassword,
            "error-text": $setup.passwordError ?? ''
          }, null, 8 /* PROPS */, ["modelValue", "disabled", "error-text"])
        ]),
        ($setup.showResetPasswordLink)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_RouterLink, {
                to: "/reset-password",
                class: "text-orange"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Forgot your password?")
                ])),
                _: 1 /* STABLE */
              })
            ]))
          : _createCommentVNode("v-if", true),
        ($setup.showSignupLink)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[7] || (_cache[7] = _createTextVNode(" Don't have a claritype account? ")),
              _createVNode(_component_RouterLink, {
                to: "/signup",
                class: "text-orange"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Let's get you set up.")
                ])),
                _: 1 /* STABLE */
              })
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}